<template>
  <v-overlay absolute :value="loader">
    <v-progress-circular indeterminate size="64"></v-progress-circular>
  </v-overlay>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "Overlay",
  computed: {
    ...mapState({
      loader: (state) => state.ui.loader,
    }),
  },
};
</script>
