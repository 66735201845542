<template lang="">
  <v-alert :type="type">
    {{ message }}
  </v-alert>  
</template>
<script>
export default {
  name: "Alert",
  props: ["message", "type"]
  // type:
  // success,info, warning,error
};
</script>
<style lang=""></style>
