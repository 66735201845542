<template lang="">
  <div class="text-center">
    <v-progress-circular
      :size="size"
      :width="width"
      color="primary"
      indeterminate
    ></v-progress-circular>
  </div>
</template>
<script>
export default {
  name: "Loader",
  props: ["size", "width"],
};
</script>

<style scoped>
.v-progress-circular {
  margin: 1rem;
}
</style>
