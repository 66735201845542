<template>
  <v-snackbar
    v-model="ui.snackbar"
    :multi-line="multiLine"
    :color="ui.snackbarColor"
    :timeout="timeout"
  >
    {{ ui.snackbarMessage }}
    <template v-slot:action="{ attrs }">
      <v-btn color="white" text v-bind="attrs" @click="hideSnackBar">
        <v-icon> close </v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      multiLine: true,
      timeout: '-1',
    };
  },
  methods: {
    hideSnackBar() {
      this.$store.dispatch("setSnackbarAction", false);
    },
  },
  computed: {
    ...mapState({
      ui: (state) => state.ui,
    }),
  },
};
</script>

<style></style>
