<template>
  <v-dialog
    :value="showModal"
    max-width="500px"
    persistent
    class="dialog-delete"
  >
    <v-card  elevation="0">
      <v-card-title  elevation="0" class="d-flex justify-center text-h5">{{ ui.msgDialogConfirm }}</v-card-title>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn class="fvl-btn transparent-primary" elevation="0" @click="closeModal">Cancelar</v-btn>
        <v-btn class="fvl-btn transparent-primary" elevation="0" @click="confirmDialog">Sí</v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from "vuex";
export default {
  props: ["showModal"],
  methods: {
    closeModal() {
      this.$store.dispatch("setIdItemDialogAction", "");
      this.$store.dispatch("setMsgDialogConfirmAction", "");
      this.$emit("actionClose")
    },
    confirmDialog() {
      this.$emit("action");
    },
  },
  computed: {
    ...mapState({
      ui: (state) => state.ui,
    }),
  },
};
</script>
