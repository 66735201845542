<template lang="">
  <div>
    <v-row>
      <v-col>
        <div class="mb-2">
          <v-btn
            class="float-right"
            color="primary"
            fab
            small
            dark
            relative
            right
            @click="onPickFile"
            title="Cargar imagenes"
          >
            <v-icon>add_photo_alternate</v-icon>
          </v-btn>
          <input
            type="file"
            style="display:none"
            ref="inputFile"
            accept="image/*"
            @change="onFilePicked"
            multiple
          />
        </div>
        <v-row>
          <v-col
            v-for="(item, index) in documentsStore.documentsSelected"
            :key="index"
            class="d-flex child-flex"
            cols="12"
            sm="4"
            lg="2"
          >
            <v-card>
              <v-btn
                class="float-right mt-2"
                elevation="2"
                fab
                x-small
                absolute
                right
                title="Eliminar Imagen"
                @click="deleteImage(index)"
              >
                <v-icon>close</v-icon>
              </v-btn>
              <v-img :src="item.link" aspect-ratio="1" class="grey lighten-2">
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      indeterminate
                      color="grey lighten-5"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
              <v-row>
                <v-col>
                  <v-divider></v-divider>
                </v-col>
              </v-row>
              <v-text-field
                label="Descripción"
                class="ma-2"
                v-model="item.description"
              ></v-text-field>
            </v-card>
          </v-col>
        </v-row>
        <!-- <v-img max-height="150" max-width="250" :src="item"></v-img> -->
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapState } from "vuex";
import { beginRequest, endRequest, validateImage } from "../../helpers";
export default {
  name: "LoadImageMultiple",
  methods: {
    onPickFile() {
      this.$refs.inputFile.click();
    },
    onFilePicked(event) {    
      // console.log(event.target.files);
      
      Array.from(event.target.files).forEach(async (file) => {
        await beginRequest(); // LOADER EN TRUE            
        const snack = await validateImage(file);  // VALIDA UN ARRAY DE IMAGENES Y LAS CARGA EN EL STORE        
        await endRequest(snack, 200);
      });
    },
    async deleteImage(index) {
      await this.$store.dispatch("removeDocumentSelectedByIndexAction", index);
    },
  },
  computed: {
    ...mapState({
      documentsStore: (state) => state.documents,
    }),
  },
};
</script>
