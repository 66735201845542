<template v-slot:extension>
<div class="container-button-add-fvl">
  <router-link :to="link">
    <v-btn
    id="button-add-fvl"
    class="transparent-secundary"
    elevation="1"
    large
    >
      <v-icon left>add_circle</v-icon>Añadir
    </v-btn>
  </router-link>
</div>
</template>

<script>
export default {
  props:["link"],
};
</script>
<style scoped></style>
