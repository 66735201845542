import links from './links';
const itemsBreadCrumbs = {
  userProfile: [
    {
      text: 'Home',
      disabled: false,
      to: links.home,
    },
    {
      text: 'Usuarios',
      disabled: false,
      to: links.user.list,
    },
    {
      text: `Usuario Detalle`,
      disabled: true,
      href: '',
    },
  ],
  userCreate: [
    {
      text: 'Home',
      disabled: false,
      to: links.home,
    },
    {
      text: 'Usuarios',
      disabled: false,
      to: links.user.list,
    },
    {
      text: 'Nuevo usuario',
      disabled: true,
      href: '',
    },
  ],
  userEdit: [
    {
      text: 'Home',
      disabled: false,
      to: links.home,
    },
    {
      text: 'Usuarios',
      disabled: false,
      to: links.user.list,
    },
    {
      text: 'Editar usuario',
      disabled: true,
      href: '',
    },
  ],
  userList: [
    {
      text: 'Home',
      disabled: false,
      to: links.home,
    },
    {
      text: 'Usuarios',
      disabled: true,
      to: '',
    },
  ],
  brandCreate: [
    {
      text: 'Home',
      disabled: false,
      to: links.home,
    },
    {
      text: 'Marcas',
      disabled: false,
      to: links.brand.list,
    },
    {
      text: 'Nueva Marca',
      disabled: true,
      href: '',
    },
  ],
  brandEdit: [
    {
      text: 'Home',
      disabled: false,
      to: links.home,
    },
    {
      text: 'Marcas',
      disabled: false,
      to: links.brand.list,
    },
    {
      text: 'Editar marca',
      disabled: true,
      href: '',
    },
  ],
  brandList: [
    {
      text: 'Home',
      disabled: false,
      to: links.home,
    },
    {
      text: 'Marcas',
      disabled: true,
      to: '',
    },
  ],
  rolesList: [
    {
      text: 'Home',
      disabled: false,
      to: links.home,
    },
    {
      text: 'Roles',
      disabled: true,
      to: '',
    },
  ],
  rolesCreate: [
    {
      text: 'Home',
      disabled: false,
      to: links.home,
    },
    {
      text: 'Roles',
      disabled: false,
      to: links.userRole.list,
    },
    {
      text: 'Nuevo Rol',
      disabled: true,
      href: '',
    },
  ],
  rolesUpdate: [
    {
      text: 'Home',
      disabled: false,
      to: links.home,
    },
    {
      text: 'Roles',
      disabled: false,
      to: links.userRole.list,
    },
    {
      text: 'Editar Rol',
      disabled: true,
      href: '',
    },
  ],
  productCreate: [
    {
      text: 'Home',
      disabled: false,
      to: links.home,
    },
    {
      text: 'Productos',
      disabled: false,
      to: links.product.list,
    },
    {
      text: 'Nuevo Producto',
      disabled: true,
      href: '',
    },
  ],
  productEdit: [
    {
      text: 'Home',
      disabled: false,
      to: links.home,
    },
    {
      text: 'Productos',
      disabled: false,
      to: links.product.list,
    },
    {
      text: 'Editar producto',
      disabled: true,
      href: '',
    },
  ],
  productList: [
    {
      text: 'Home',
      disabled: false,
      to: links.home,
    },
    {
      text: 'Productos',
      disabled: true,
      to: '',
    },
  ],
  stepCreate: [
    {
      text: 'Home',
      disabled: false,
      to: links.home,
    },
    {
      text: 'Etapas',
      disabled: false,
      to: links.step.list,
    },
    {
      text: 'Nueva Etapa',
      disabled: true,
      href: '',
    },
  ],
  stepEdit: [
    {
      text: 'Home',
      disabled: false,
      to: links.home,
    },
    {
      text: 'Etapas',
      disabled: false,
      to: links.step.list,
    },
    {
      text: 'Editar etapa',
      disabled: true,
      href: '',
    },
  ],
  stepList: [
    {
      text: 'Home',
      disabled: false,
      to: links.home,
    },
    {
      text: 'Etapas',
      disabled: true,
      to: '',
    },
  ],
  projectCreate: [
    {
      text: 'Home',
      disabled: false,
      to: links.home,
    },
    {
      text: 'Proyecto',
      disabled: false,
      to: links.project.list,
    },
    {
      text: 'Nueva Proyecto',
      disabled: true,
      href: '',
    },
  ],
  projectEdit: [
    {
      text: 'Home',
      disabled: false,
      to: links.home,
    },
    {
      text: 'Proyecto',
      disabled: false,
      to: links.project.list,
    },
    {
      text: 'Editar Proyecto',
      disabled: true,
      href: '',
    },
  ],
  projectList: [
    {
      text: 'Home',
      disabled: false,
      to: links.home,
    },
    {
      text: 'Proyecto',
      disabled: true,
      to: '',
    },
  ],
  projectDetail: [
    {
      text: 'Home',
      disabled: false,
      to: links.home,
    },
    {
      text: 'Detalle Proyecto',
      disabled: true,
      href: '',
    },
  ],
};

export default itemsBreadCrumbs;
