<template lang="">
  <div>
    <div class="mb-2">
      <v-btn
        class="float-right fvl-btn green-primary"
        icon
        large
        @click="onPickFile"
        title="Cargar imagen"
      >
        <v-icon>add_photo_alternate</v-icon>
      </v-btn>
      <input
        type="file"
        style="display:none"
        ref="inputFile"
        accept="image/*"
        @change="onFilePicked"
      />
    </div>
    <v-row class="d-flex justify-center">
      <v-col
        v-for="(item, index) in documentsStore.documentsSelected"
        :key="index"
        class="d-flex child-flex"
        cols="12"
        sm="6"
        lg="6"
      >
        <v-card>
          <v-btn
            class="float-right mt-2"
            elevation="2"
            fab
            x-small
            absolute
            right
            title="Eliminar Imagen"
            @click="deleteImage(index)"
          >
            <v-icon>close</v-icon>
          </v-btn>
          <v-img
            :src="item.link"
            aspect-ratio="1"
            class="grey lighten-2"
            max-height="300"
          >
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular
                  indeterminate
                  color="grey lighten-5"
                ></v-progress-circular>
              </v-row>
            </template>
          </v-img>
        </v-card>
      </v-col>
    </v-row>
    <!-- <v-img max-height="150" max-width="250" :src="item"></v-img> -->
  </div>
</template>
<script>
import { mapState } from "vuex";
import { beginRequest, endRequest, validateImage } from "../../helpers";
export default {
  name: "LoadImageSingle",
  methods: {
    onPickFile() {
      this.$refs.inputFile.value = "";
      this.$refs.inputFile.click();
    },
    async onFilePicked(event) {
      await this.$store.dispatch("removeDocumentsSelectedAction");
      const file = event.target.files[0];
      await beginRequest();
      const snack = await validateImage(file);
      await endRequest(snack, 200);
    },
    async deleteImage(index) {
      await this.$store.dispatch("removeDocumentsSelectedAction");
    },
  },
  computed: {
    ...mapState({
      documentsStore: (state) => state.documents,
      user: (state) => state.user,
    }),
  },
};
</script>
