<template lang="">
  <div >
    <v-breadcrumbs class="breadcrumbs-fvl" :items="items" divider="/"></v-breadcrumbs>
  </div>
</template>
<script>
export default {
  name: "BreadCrumbsComponent",
  props: ["items"],
};
</script>
