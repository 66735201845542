<template lang="">
  <v-btn
    :class="getClass"
    :color="color"
    :type="type"
    :disabled="disabled"
    elevation="0"
  >
    {{name}}
  </v-btn>
</template>
<script>
export default {
    props:["disabled", "type", "color", "classes", "name"],
    computed:{
      getClass(){
        return `${this.classes} text-capitalize`;
      }
    }
};
</script>
<style lang=""></style>
