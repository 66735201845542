<template>
  <v-progress-linear
    v-if="loader"
    indeterminate
    color="primary"
  ></v-progress-linear>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "LoaderLinear",
  computed: {
    ...mapState({
      loader: (state) => state.ui.loader,
    }),
  },
};
</script>
