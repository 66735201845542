import store from '../store';

export const endRequest = async (snackbar, status) => {
    await store.dispatch('setSnackbarAction', snackbar);    
    await store.dispatch('setLoaderAction', { value: false });
    if (status === 401 && protectedRequest) { // NO AUTORIZADO - TOKEN VENCIDO
        await store.dispatch('logOut');
        return false;
    }
    return true;
}