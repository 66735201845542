<template lang="">
  <v-overlay absolute :value="loading">
    <v-progress-circular indeterminate size="64"></v-progress-circular>
  </v-overlay>
</template>
<script>
export default {
  name: "LoaderEntity",
  props: ["loading"],
};
</script>

<style scoped>
.v-progress-circular {
  margin: 1rem;
}
</style>
