<template v-slot:extension>
  <router-link :to="link">
    <v-btn class="mt-10" color="primary" fab bottom right top absolute>
      <v-icon>add</v-icon>
    </v-btn>
  </router-link>
</template>

<script>
export default {
  props:["link"],
};
</script>
<style scoped></style>
rou
