// HELPER QUE SE ENCARGA DE VALIDAR FORMATO, TAMAÑO Y QUE EL NAVEGADOR PERMITA CARGAR IMAGENES Y LAS ALMACENA EN EL STORE
import store from '../store';

export const validateImage = async (file) => {
    let cantReject = 0;
    let cantExced = 0;
    let snack = false;
    if (!window.FileReader) {
        await store.dispatch(
            "setSnackbarMessageAction",
            "El navegador no permite subir imágenes"
        );
        await store.dispatch("setSnackbarColorAction", "red");
        snack = true;
        return;
    }

    // VERIFICA FORMATO DE IMAGENES Y TAMAÑO
    if (/\.(jpg|JPG|png|PNG|jpeg|JPEG|gift|GIFT)$/i.test(file.name)) {
        // VALIDA TAMAÑO IMAGEN (MAX - 5 MEGABYTES)
        if (file.size <= 5000000) {
            const fileReader = new FileReader();
            const data = {};
            fileReader.addEventListener("load", async () => {
                data.link = fileReader.result;
                data.doc_file = file;
                data.id = null;
                data.description = "";
                await store.dispatch("addDocumentsSelectedAction", data);
            });
            fileReader.readAsDataURL(file);
        } else {
            cantExced++;
        }
    } else {
        cantReject++;
    }

    // SIN ALGUNO DE LOS ARCHIVOS QUE INTENTA SUBIR NO ES IMAGEN CANTREJECT > 0 - MUESTRA MJE
    if (cantReject > 0 || cantExced > 0) {
        // si algun ar
        await store.dispatch(
            "setSnackbarMessageAction",
            "Alguno de los archivos no es una imágen o excede el tamaño de 5 Mb."
        );
        await store.dispatch("setSnackbarColorAction", "red");
        snack = true;
    } else {
        snack = false;
    }
    return snack;
}