<template>
  <v-snackbar id="fvl-snackbar" :value="snack" multi-line :color="colorSnack" timeout="-1">
    {{ snackbarMessage }}
    <template v-slot:action="{ attrs }">
      <v-btn color="white" text v-bind="attrs" @click="hideSnackBar">
        <v-icon> close </v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  name: "SnackBarEntity",
  props: ["snack", "colorSnack", "snackbarMessage"],
  methods: {
    hideSnackBar() {
      this.$emit("action");
    },
  },
};
</script>

<style></style>
