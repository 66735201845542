<template>
  <v-dialog
    v-model="ui.dialogShow"
    max-width="500px"
    persistent
    class="dialog-delete"
  >
    <v-card elevation="0">
      <v-card-title class="d-flex justify-center text-h5">{{ ui.msgDialogConfirm }}</v-card-title>
      <v-card-actions elevation="0">
        <v-spacer></v-spacer>
        <v-btn class="fvl-btn transparent-primary" elevation="0" @click="closeModal">No</v-btn>
        <v-btn class="fvl-btn transparent-primary" elevation="0"  @click="confirmDialog">Sí</v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from "vuex";
export default {
  methods: {    
    closeModal() {
      this.$store.dispatch('setIdItemDialogAction', '');
      this.$store.dispatch('setMsgDialogConfirmAction', '');
      this.$store.dispatch('setDialogShowAction');            
    },
    confirmDialog() {
      this.$emit("action");
    },
  },
  computed:{
     ...mapState({
      ui: (state) => state.ui,
    }), 
  }
};
</script>